import React from "react"
import SEO from "../components/SEO"

const IndexPage = () => (
  <SEO
    title="Management Consulting"
    description="MediaLink helps companies accelerate change and growth, with business practices spanning across media, advertising, investment, and emerging technologies."
  />
)

export default IndexPage
